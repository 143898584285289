<script context="module">
  import { get, writable } from 'svelte/store';

  let available = writable(storagePresent());

  export function storageAvailable() {
    return get(available);
  }

  // set sound "off" by default
	export function storageGetSound() {
		return storageAvailable() && localStorage.getItem('sound_enabled') === "true";
	}

  export function storageSetSound(val) {
		if (storageAvailable()) {
			localStorage.setItem('sound_enabled', val);
		}
  }

	function storagePresent() {
		try {
			const storage = window.localStorage,
				x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch(e) {
			return false;
		}
	}
</script>
