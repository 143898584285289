import { get, writable } from 'svelte/store';

function add(current, additions) {
  current.set([...get(current), ...additions]);
}

function remove(current, subtractions) {
  current.set(
    get(current).filter((c) => !subtractions.includes(c))
  );
}

function canAddCard(suitName, rankValue, cards) {
  if (cards.length === 0) {
    // only an ace can be placed as 1st
    return rankValue === 1;
  } else {
    const last = cards.at(-1);
    return suitName === last.suit.name && rankValue === last.rank.value + 1;
  }
}

function createFoundation(index) {
  const { subscribe, set, update } = writable();
  const cards = writable([]);

  return {
    index: index,
    add: (additions) => add(cards, additions),
    remove: (subtractions) => remove(cards, subtractions),
    canAddCard: (suitName, rankValue) => canAddCard(suitName, rankValue, get(cards)),
    cards: cards,
    location: 'foundation',
    set: set,
    subscribe,
    update: update
  };
}

export const FoundationModel = createFoundation;
