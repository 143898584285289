<script>
  import { tick } from 'svelte';
  import { get } from 'svelte/store';
  import { attemptAutoFinish, transferCard } from './game.svelte';
  import { addScore } from './controls/score.svelte';
  import { UndoStack } from './model/undo';
  import { foundations } from './model/foundations';

  export let data;
  export let index = 0;
  let {id, faceUp, rank, selected, suit} = data;
  let cardElement;

  $: highlight = $selected;
  $: $faceUp, faceUpChanged()

  function doubleClicked() {
    if (!$faceUp) { return false; }

    let foundation = get(foundations).find((f) => {
      return f.canAddCard(suit.name, rank.value);
    });

    if (foundation) {
      const source = transferCard(id, foundation);

      let scoreToAdd = 10; // +10 to foundation
      const nextCard = get(source.cards).at(-1);
      const wasFaceUp = get(nextCard?.faceUp);
      nextCard?.faceUp.set(true);

      if (source.location === 'tableau' && wasFaceUp === false) {
        scoreToAdd += 5; // +5 to reveal tableau card
      }

      const scoreChange = addScore(scoreToAdd);

      UndoStack.add({
        undo: () => {
          transferCard(id, source);
          if (wasFaceUp) {
            nextCard?.faceUp.set(true);
          } else {
            nextCard?.faceUp.set(false);
          }
          addScore(scoreChange);
        }
      });

      attemptAutoFinish();
    }
  }

  function initDraggable() {
    window.$(cardElement)?.draggable({
      containment: 'body',
      cursor: 'move',
			revert: 'invalid',
			revertDuration: 300,
			snap: true,
			snapMode: 'inner',
			snapTolerance: 32,
      zIndex: 99,
			drag: function(event, ui) {
				window.$(this).nextAll().each(function(index, item) {
					index += 1;
					window.$(item).css({
						top: ui.position.top + (index*24),
						left: ui.position.left
					});
					window.$(item).addClass('drag-buddy');
				});
			}
    });
  }

  async function faceUpChanged() {
    await tick();

    if ($faceUp) {
      if (!window.$(cardElement).draggable("instance")) {
        initDraggable();
      } else {
        window.$(cardElement)?.draggable("enable");
      }
    } else {
      window.$(cardElement)?.draggable("disable");
    }
  }
</script>

<style>
  .highlight {
    outline-style: auto;
    outline-width: medium;
    outline-color: var(--theme_secondary_color);
  }

  card-t {
    bottom: unset;
    left: 0px;
  }
</style>

{#if $faceUp}
  <card-t on:dblclick={doubleClicked}
    bind:this={cardElement}
    class="draggable-card"
    class:highlight
    data-cardid={id}
    data-color={suit.color}
    data-suitname={suit.name}
    data-value={rank.value}
    rank={rank.name}
    suit={suit.name}
    bordercolor=""
    borderline=""
    borderradius=""
    cardcolor="#fff"
    courtcolors="#DB3,#cc3333,#527788,#333,#333,8"
    suitcolor="#333,#cc3333,#cc3333,#333"
    opacity=".9"
    style="top:{index*24}px;"
  ></card-t>
{:else}
  <card-t
    rank="0"
    backcolor="#527788"
    backtext=" "
    style="top:{index*24}px;"
  ></card-t>
{/if}

<!--
  Default
  bordercolor="#444"
  borderline="1"
  borderradius="12"
  cardcolor="#fff"
  courtcolors="#DB3,red,#44F,#000,#000,4" // (gold,red,blue,black,blacklines,linethickness)
  suitcolor="#000,red,red,#000"

  Variant
  courtcolors="gold,red,green,orange,#000,4"
-->
