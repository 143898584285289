<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
	import { scale } from 'svelte/transition';
	import { expoOut } from 'svelte/easing';
  import { attemptAutoFinish, resetDragGroup, transferCard } from './game.svelte';
  import { addScore } from './controls/score.svelte';
  import { UndoStack } from './model/undo';
  import Card from './card.svelte';

  export let foundation;
  export let cards;
  let droppableElement;

  function canAddCard(cardValue, suitName) {
		const last = $cards.at(-1);

		//	only an ace can be placed first
		if (!last) {
			return cardValue === 1;
		} else {
			return suitName === last.suit.name && cardValue === last.rank.value + 1;
		}
  }

  onMount(() => {
    window.$(droppableElement).droppable({
      accept: '.draggable-card',
      drop: function(event, ui) {
        const cardId = ui.draggable.data('cardid');
        const cardValue = ui.draggable.data('value');
        const suitName = ui.draggable.data('suitname');

        // only 1 card at a time should be droppable on foundation
        const validMove = canAddCard(cardValue, suitName) && window.$('.drag-buddy').length === 0;

				if (validMove) {
					const source = transferCard(cardId, foundation);

          const nextCard = get(source.cards).at(-1);
          const wasFaceUp = get(nextCard?.faceUp);
          nextCard?.faceUp.set(true);

          let scoreToAdd = 0;

					if (source.location === 'deck') {
						//	score: +10 'waste to foundation'
						scoreToAdd += 10;
					} else if (source.location === 'tableau') {
						//	score: +10 'tableau to foundation'
						scoreToAdd += 10;
            if (wasFaceUp === false) {
              scoreToAdd += 5; // +5 to reveal tableau card
            }
					}

          const scoreChange = addScore(scoreToAdd);

          UndoStack.add({
            undo: () => {
							transferCard(cardId, source);
              if (wasFaceUp) {
                nextCard?.faceUp.set(true);
              } else {
                nextCard?.faceUp.set(false);
              }
              addScore(scoreChange);
            }
          });

          attemptAutoFinish();
				} else {
          const originalPosition = window.$(ui.draggable).data()['uiDraggable'].originalPosition;
          resetDragGroup(originalPosition);

          window.$(ui.draggable).draggable("option", "revert", true);
				}
      }
    });
  });
</script>

<style>

</style>

<div class="card-outline" bind:this={droppableElement}>
  {#each $cards as card}
    <div in:scale="{{duration: 500, delay: 0, opacity: 1, start: 1.5, easing: expoOut}}">
      <Card data={card} index={0} />
    </div>
  {/each}
</div>
