<script>
  import Header from './game/header/header.svelte';
  import Game from './game/game.svelte';
</script>

<style>
	:global(body) {
		/* this will apply to <body> */
		background-color: var(--theme_primary_color);
	}

  .wrapper {
    margin: 0 auto;
    max-width: 90%;
    padding: 0;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
  }

  @media only screen and (min-width: 1067px) {
    .wrapper {
      max-width: 960px;
    }
  }
</style>

<Header />

<div class="wrapper">
  <Game />
</div>
