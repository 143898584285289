<script>
  import { expoOut } from 'svelte/easing';
  import { Deck } from './model/deck';
  import Card from './card.svelte';

  const { up } = Deck;

  function flip(node, params) {
		return {
			delay: params.delay || 0,
			duration: params.duration || 500,
      css: (t, u) => {
        const eased = expoOut(t);

        return `transform: rotateY(${eased * 180}deg)`
      }
		};
  }
</script>

<style>
  .card-container {
    transform: rotateY(180deg);
    transform-style: preserve-3d;
  }
  .card-face {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
  }
  .front {
    transform: rotateY(180deg);
  }
</style>

<div class="card-outline">
  {#each $up as card}
    <div class="card-container" in:flip>
      <div class="card-face front">
        <Card data={card} />
      </div>
      <card-t class="card-face back" rank="0" backcolor="#527788" backtext=" "></card-t>
    </div>
  {/each}
</div>
