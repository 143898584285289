<script>
  let active = false;

  function toggleModal() {
    active = !active;
  }
</script>

<style>
	.help-button {
		background-repeat: no-repeat;
		cursor: pointer;
		height: 24px;
		width: 24px;
	}

  .help-modal {
    position: absolute;
    top: -100%;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    color: #444;
    transition: top 0ms, opacity 500ms;
    z-index: 101;
  }
  .help-modal.active {
    top: 0;
    opacity: 1;
    position: fixed;
  }
  .help-modal .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }
  .help-modal .content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -250px 0 0 -300px;
    width: 600px;
    min-height: 300px;
    background-color: #fff;
    z-index: 1;
    padding: 15px 30px;
    border-radius: 10px;
    line-height: 1.5em;
  }
  .help-modal .close.button {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0px 15px;
    transition: color 250ms, background-color 250ms, border 250ms;
  }
  .help-modal .close.button:hover {
    background-color: var(--theme_secondary_color);
    color: #fff;
    border-color: #fff;
  }

  h2 {
    color: var(--theme_primary_color);
  }

  hr.diamond {
    color: var(--theme_secondary_color);
    padding: 0;
    border: none;
    border-top-style: double;
    border-top-width: medium;
    border-top-color: var(--theme_secondary_color);
    text-align: center;
    margin-bottom: -10px;
  }
  hr.diamond:after {
    content: "◊";
    display: inline-block;
    position: relative;
    top: -0.5em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: white;
  }
</style>

<div class="help-button" on:click={toggleModal}>
  <img src="/images/help.png" alt="help button" />
</div>

<div class="help-modal" class:active>
  <div class="content">
    <div class="close button" on:click={toggleModal}>Close</div>
    <h2>Klondike Solitaire (Draw One)</h2>
    <hr class="diamond">
    <p>
      The goal of Klondike is to move all cards into the 4 foundation piles in the top right.
      Each foundation may only hold ordered cards of the same suit, from Ace (lowest) to King (highest).
    </p>
    <p>
      The 7 "tableaux" piles at the bottom accept cards stacked by alternating colors (red/black), and are ordered from King (highest) to Ace (lowest).
      You may move cards between tableau piles, or to a foundation from the tableaux.
    </p>
    <p>
      The top left face-down pile of cards is your deck. Click on the deck to draw a card to the "waste" pile; from there it may be dragged to a foundation or tableau pile. Once the deck is empty, you may recycle the waste pile by clicking the empty deck section.
    </p>
    <p>
      For more information, see this <a target="_blank" href="https://en.wikipedia.org/wiki/Klondike_(solitaire)">wikipedia article</a>.
    </p>
  </div>
  <div class="overlay" on:click={toggleModal}></div>
</div>
