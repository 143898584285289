<script>
  import Help from './help.svelte';
  import Sound from './sound.svelte';
  import Theme from './theme.svelte';
</script>

<style>
	.header {
    align-items: center;
		background-color: var(--theme_secondary_color);
    color: #fff;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 0 40px;
	}

  .logo {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
  }

  .settings {
    column-gap: 1rem;
    display: flex;
  }
</style>

<div class="header">
  <div class="logo">solitaire world</div>
  <div class="settings">
    <Sound />
    <Theme />
    <Help />
  </div>
</div>

