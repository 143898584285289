<script>
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { attemptAutoFinish, resetDragGroup, transferCard } from './game.svelte';
  import { addScore } from './controls/score.svelte';
  import { UndoStack } from './model/undo';
  import Card from './card.svelte';

  export let tableau;
  export let cards;
  let droppableElement;

  function canAddCard(cardValue, cardColor) {
		const last = $cards.at(-1);

		//	only a king can be placed as 1st
		if (!last) {
			return cardValue === 13;
		} else {
			return cardColor !== last.suit.color && cardValue === last.rank.value - 1;
		}
  }

	onMount(() => {
    window.$(droppableElement).droppable({
      accept: '.draggable-card',
      drop: function(event, ui) {
        const cardId = ui.draggable.data('cardid');
        const cardValue = ui.draggable.data('value');
        const cardColor = ui.draggable.data('color');
        const validMove = canAddCard(cardValue, cardColor);

        if (validMove) {
          const source = transferCard(cardId, tableau);
					let droppedCardIds = [];
          let buddyCardId = null;

          droppedCardIds.push(cardId);

					window.$('.drag-buddy').each(function(index, ele) {
						buddyCardId = window.$(ele).data('cardid');
						transferCard(buddyCardId, tableau);
						droppedCardIds.push(buddyCardId);
						window.$(ele).removeClass('drag-buddy');
					});

          const nextCard = get(source.cards).at(-1);
          const wasFaceUp = get(nextCard?.faceUp);
          nextCard?.faceUp.set(true);

          let scoreToAdd = 0;
          scoreToAdd += source.location === 'deck' ? 5 : source.location === 'foundation' ? -15 : 0;
          if (source.location === 'tableau' && wasFaceUp === false) {
            scoreToAdd += 5; // +5 to reveal tableau card
          }
          const scoreChange = addScore(scoreToAdd);

          UndoStack.add({
            undo: () => {
							droppedCardIds.forEach((id) => {
								transferCard(id, source);
							});
              if (wasFaceUp) {
                nextCard?.faceUp.set(true);
              } else {
                nextCard?.faceUp.set(false);
              }
              addScore(scoreChange);
            }
          });

          attemptAutoFinish();
        } else {
          const originalPosition = window.$(ui.draggable).data()['uiDraggable'].originalPosition;
          resetDragGroup(originalPosition);

          window.$(ui.draggable).draggable("option", "revert", true);
        }
      }
    });
  });
</script>

<style>
  .card-container {
    height: 100vh;
    position: absolute;
    width: 100%;
  }
</style>

<div class="card-outline">
  <div class="card-container" bind:this={droppableElement}>
    {#each $cards as card, i}
      <Card data={card} index={i} />
    {/each}
  </div>
</div>
