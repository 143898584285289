import { get, writable } from 'svelte/store';
import { UndoStack } from './undo';
import { addScore } from '../controls/score.svelte';

const suits = [
  { name: 'Hearts', color: 'red' },
  { name: 'Diamonds', color: 'red' },
  { name: 'Spades', color: 'black' },
  { name: 'Clubs', color: 'black' }
];

const ranks = [
  { name: 'Ace', value: 1 },
  { name: 'Two', value: 2 },
  { name: 'Three', value: 3 },
  { name: 'Four', value: 4 },
  { name: 'Five', value: 5 },
  { name: 'Six', value: 6 },
  { name: 'Seven', value: 7 },
  { name: 'Eight', value: 8 },
  { name: 'Nine', value: 9 },
  { name: 'Ten', value: 10 },
  { name: 'Jack', value: 11 },
  { name: 'Queen', value: 12 },
  { name: 'King', value: 13 }
];

// array shuffling algorithm: https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
function fisherYates(arr) {
  var i = arr.length;
  if (i === 0)
    return false;
  while (--i) {
    var j = Math.floor(Math.random() * (i + 1));
    var tempi = arr[i];
    var tempj = arr[j];
    arr[i] = tempj;
    arr[j] = tempi;
  }
}

function shuffle() {
  let id = 0;
  const cards = [].concat(...suits.map(suit => {
    return ranks.map(rank => {
      id += 1;
      return card({
        id: id,
        rank: rank,
        suit: suit
      })
    });
  }));

  fisherYates(cards);

  return cards;
}

function card(data) {
  return {
    id: data.id,
    rank: data.rank,
    suit: data.suit,
    name: `${data.rank.name} of ${data.suit.name}`,
    faceUp: writable(false),
    selected: writable(false)
  };
}

function draw(d, u) {
  const down = get(d);
  const up = get(u);

  if (down.length > 0) {
    const card = down[0];
    // console.log(card)

    d.set(down.filter(c => c.id !== card.id));
    u.set(up.concat(card));
    card.faceUp.set(true);

    UndoStack.add({
      undo: () => {
        d.set(down.concat(card));
        u.set(up.filter(c => c.id !== card.id));
      }
    });
  } else {
    // switch stacks
    d.set(up);
    u.set([]);

    // 'recycle waste when playing by ones'
    const scoreChange = addScore(-100);

    UndoStack.add({
      undo: () => {
        d.set([]);
        u.set(up);
        addScore(scoreChange);
      }
    });
  }
}

function reset(down, up) {
  // down.set(shuffle().slice(0, 10));
  down.set(shuffle());
  up.set([]);
}

function add(current, additions) {
  current.set([...get(current), ...additions]);
}

function remove(current, subtractions) {
  current.set(
    get(current).filter((c) => !subtractions.includes(c))
  );
}

function createDeck() {
  const { subscribe, set, update } = writable();
  const down = writable([]);
  const up = writable([]);

  return {
    down: down,
    up: up,
    cards: up,
    add: (additions) => add(up, additions),
    remove: (subtractions) => remove(up, subtractions),
    draw: () => draw(down, up),
    reset: () => reset(down, up),
    isEmpty: () => get(up).length === 0 && get(down).length === 0,
    location: 'deck',
    set: set,
    subscribe,
    update: update
  };
}

export const Deck = createDeck();
