import { writable } from 'svelte/store';

function createTableaux() {
  const { subscribe, set, update } = writable([]);

  return {
    set: set,
    subscribe,
    update: update
  };
}

export const tableaux = createTableaux();
