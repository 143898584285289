const enabled = document.location.hostname.indexOf("solitaire-world.com") !== -1;

function track(eventName) {
  if (!enabled) { return false; }

  // ga('send', {
  //   hitType: 'event',
  //   eventCategory: 'Game',
  //   eventAction: eventName
  // });
}

export const Analytics = {
  track: track
};
