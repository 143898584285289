<script>
  import { get } from 'svelte/store';
  import { Deck } from './model/deck';
  import { playSound } from './header/sound.svelte';

  const { down, up } = Deck;
  $: empty = $down.length === 0;

  function draw() {
    deselectUpCard();
    Deck.draw();
    playSound('cardFlip');
  }

  function deselectUpCard() {
    // make sure the up card is deselected
    get(up).at(-1)?.selected.set(false);
  }
</script>

<style>

</style>

<div class="card-outline" on:click={draw}>
  {#if !empty}
    <card-t rank="0" backcolor="#527788" backtext=" "></card-t>
  {/if}
</div>
