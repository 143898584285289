import { get, writable } from 'svelte/store';

function add(current, additions) {
  current.set([...get(current), ...additions]);
}

function remove(current, subtractions) {
  current.set(
    get(current).filter((c) => !subtractions.includes(c))
  );
}

function createTableau(index) {
  const { subscribe, set, update } = writable();
  const cards = writable([]);

  return {
    index: index,
    add: (additions) => add(cards, additions),
    remove: (subtractions) => remove(cards, subtractions),
    cards: cards,
    last: () => get(cards).at(-1),
    location: 'tableau',
    set: set,
    subscribe,
    update: update
  };
}

export const TableauModel = createTableau;
