<script>
  import { Analytics } from '../../../analytics';
  import { UndoStack } from '../model/undo';
  import { newGame } from '../game.svelte';
  import Score from './score.svelte';

  $:disabled = $UndoStack.length === 0;

  function reset() {
    newGame();
    Analytics.track('newGameClicked');
  }

  function undo() {
    UndoStack.undo();
    Analytics.track('undoClicked');
  }
</script>

<style>
  .controls {
    align-items: center;
    color: #fff;
    display: flex;
    height: 60px;
  }

  .button {
    background-color: none;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    padding: 0.25rem 1rem;
    position: absolute;
    text-align: center;
    transition: background-color 250ms, color 250ms, padding 250ms;
  }

  .undo.button {
    left: 0;
    padding-left: 0;
  }
  .undo.button:hover {
    background-color: #fff;
    color: var(--theme_primary_color);
    font-weight: bold;
    padding-left: 1rem;
  }
  .undo.button.disabled {
    cursor: default;
    opacity: 0.5;
  }
  .undo.button.disabled:hover {
    background-color: unset;
    color: #fff;
    font-weight: normal;
    padding-left: 0;
  }

  .new-game.button {
    padding-right: 0;
    right: 0;
  }
  .new-game.button:hover {
    background-color: #fff;
    color: var(--theme_primary_color);
    font-weight: bold;
    padding-right: 1rem;
  }
</style>

<div class="controls">
  <div class="undo button" class:disabled on:click={undo}>undo</div>
  <Score />
  <div class="new-game button" on:click={reset}>new game</div>
</div>
