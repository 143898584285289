<script context="module">
	import { get, writable } from 'svelte/store';
	import { storageSetSound } from '../storage/storage.svelte';

  let cardFlip = new Audio("sounds/card-flip.wav"); // buffers automatically when created
  let score = new Audio("sounds/score.wav");
  let foundation = new Audio("sounds/foundation.wav");
	let enabled = writable(false);

  export function playSound(name) {
		if (!get(enabled)) return false;

		switch (name) {
			case 'cardFlip':
				cardFlip.play();
				cardFlip.currentTime=0;
				break;

			case 'score':
  			score.play();
	  		score.currentTime=0;
				break;

			case 'foundation':
				foundation.play();
				foundation.currentTime=0;
				break;

      default:
        break;
		}
  }

	export function setSoundEnabled(val) {
		enabled.set(val);
	}

	function clicked() {
		const newSetting = !get(enabled);
		setSoundEnabled(newSetting);
		storageSetSound(newSetting);
	}
</script>

<style>
	.sound {
		background-repeat: no-repeat;
		cursor: pointer;
		height: 24px;
		width: 24px;
	}
</style>

<div class="sound" on:click={clicked}>
	{#if $enabled}
		<img src="/images/volume-on.png" alt="toggle-sound" />
	{:else}
		<img src="/images/volume-off.png" alt="toggle-sound" />
	{/if}
</div>
