<script context="module">
  export function newGame() {
    let numTableaux = 7;
    let numFoundations = 4;

    resetScore();
    Deck.reset();
    const down = get(Deck.down);

    foundations.set(
      [...Array(numFoundations)].map((_, index) => {
        return FoundationModel(index)
      })
    );

    tableaux.set(
      [...Array(numTableaux)].map((tab, index) => {
        return TableauModel(index)
      })
    );

    // need to run this separately for async tick shuffling
    // in support of resetting game
    get(tableaux).forEach(async (tab, index) => {
      let cards = [];
      let card = null;

      await tick();

      // facedown cards
      for (var i=0; i<index; i++) {
        card = down.pop();
        cards.push(card);
      }

      // faceup card
      card = down.pop();
      card.faceUp.set(true);
      cards.push(card);

      tab.add(cards);
    })    
  }

  export function transferCard(cardId, destination) {
    // console.log(cardId, destination);

    let transferred = false;
    let source = null;

    get(tableaux).forEach((tableau) => {
      get(tableau.cards).forEach((card) => {
        if (!transferred && card.id === cardId) {
          source = tableau;
          tableau.remove([card]);
          destination.add([card])
          transferred = true;
        }
      });
    });

    get(Deck.up).forEach((card) => {
      if (!transferred && card.id === cardId) {
        source = Deck;
        Deck.remove([card]);
        destination.add([card]);
        transferred = true;
      }
    });

    get(foundations).forEach((foundation) => {
      get(foundation.cards).forEach((card) => {
        if (!transferred && card.id === cardId) {
          source = foundation;
          foundation.remove([card]);
          destination.add([card]);
          transferred = true;
        }
      });
    });

    return source;
  }

  export function resetDragGroup(originalPosition) {
    window.$('.drag-buddy').each(function(index, ele) {
      var top = originalPosition.top + ((index+1) * 24);
      var left = originalPosition.left;
      window.$(ele).css('top', top);
      window.$(ele).css('left', left);
      window.$(ele).removeClass('drag-buddy');
    });
  }

  export function attemptAutoFinish() {
    const tableauxFaceUp = get(tableaux).every(({cards}) => {
      return get(cards).every(card => get(card.faceUp) === true);
    });

    if (Deck.isEmpty() && tableauxFaceUp) {
      autoFinish();
    }
  }

  function autoFinish() {
		//	Place all tableaux cards into foundations, once the deck is empty
		//	and there are no face-down tableaux cards.

		function foundationsFull() {
			return get(foundations).every(foundation => {
				return get(foundation.cards).length === 13;
			});
		}

    function playCards() {
      let card = null;
      let tabCounter = 0;
      let promises = [];

      get(tableaux).forEach(tableau => {
        tabCounter += 1;

        promises.push(new Promise((resolve, reject) => {
          setTimeout(() => {
            let sourceCardIndex = 0;

            card = get(tableau.cards).at(-1);
            if (card) {
              get(foundations).forEach(foundation => {
                if (canAddCardToFoundation(card, get(foundation.cards))) {
                  tableau.remove([card]);
                  foundation.add([card]);
                  addScore(10);
                }
              });
            }
            resolve();
          }, 100 * tabCounter)
        }))
      });

      Promise.all(promises).then(() => {
        if (!foundationsFull()) {
          playCards();
        }
      });
    }

    UndoStack.set([]);
    playCards();

    Analytics.track('gameWon');
  }

  function canAddCardToPile(card, last, location) {
    if (location === 'deck') return false;

    if (location === 'tableau') {
  	  return card.suit.color !== last.suit.color && card.rank.value === last.rank.value - 1;
    }

    if (location === 'foundation') {
      return card.suit.name === last.suit.name && card.rank.value === last.rank.value + 1;
    }
  }

  function canAddCardToFoundation(card, foundCards) {
    if (foundCards.length === 0) {
      // only an ace can be placed as 1st
      return card.rank.value === 1;
    } else {
      return canAddCardToPile(card, foundCards.at(-1), 'foundation');
    }
  }
</script>

<script>
  import { onMount, tick } from 'svelte';
  import { get, writable } from 'svelte/store';

  import { Analytics } from '../../analytics';
  import Storage, { storageGetSound } from './storage/storage.svelte';
  import { setSoundEnabled } from './header/sound.svelte';
  import Controls from './controls/controls.svelte';
  import { addScore, resetScore } from './controls/score.svelte';
  import { Deck } from './model/deck';
  import { FoundationModel } from './model/foundation';
  import { foundations } from './model/foundations';
  import { TableauModel } from './model/tableau';
  import { tableaux } from './model/tableaux';
  import { UndoStack } from './model/undo';
  import Deckdown from './deckdown.svelte';
  import Deckup from './deckup.svelte';
  import Foundation from './foundation.svelte';
  import Tableau from './tableau.svelte';

  let score = {
    value: writable(0),
    big: writable(false),
    small: writable(false)
  };

	onMount(() => {
    newGame();
		setSoundEnabled(storageGetSound());
	});
</script>

<style>
  .row-top {
    column-gap: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .decks {
    column-gap: 1rem;
    display: flex;
    flex-grow: 1;
    z-index: 1;
  }

  .foundations {
    column-gap: 1rem;
    display: flex;
    flex-grow: 2;
  }

  .row-bottom {
    column-gap: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (max-width: 799px) {
    .decks {
      column-gap: 0.5rem;
    }
    .foundations {
      column-gap: 0.5rem;
    }
    .row-bottom {
      column-gap: 0.5rem;
    }
  }
</style>

<div class="game">
  <Controls />

  <div class="row-top">
    <div class="decks">
      <Deckdown />
      <Deckup />
    </div>
    <div class="foundations">
      {#each $foundations as foundation}
        <Foundation foundation={foundation} cards={foundation.cards} />
      {/each}
    </div>
  </div>

  <div class="row-bottom">
    {#each $tableaux as tableau}
      <Tableau tableau={tableau} cards={tableau.cards} />
    {/each}
  </div>
</div>

<Storage />
