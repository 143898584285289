<script>
  import { onMount } from 'svelte';
  import { Analytics } from '../../../analytics';

  let themes = [
    {
      name: "A",
      colors: {
        primary: "#6B8E23",
        secondary: "#52770B",
        text: "#fff"
      }
    },
    {
      name: "B",
      colors: {
        primary: "#00A0B0",
        secondary: "#EB6841",
        text: "#fff"
      }
    },
    {
      name: "C",
      colors: {
        primary: "#53777A",
        secondary: "#D95B43",
        text: "#fff"
      }
    },
    {
      name: "D",
      colors: {
        primary: "#556270",
        secondary: "#424242",
        text: "#fff"
      }
    }
  ];

  let currentTheme = 0;

  onMount(() => {
    updateStyles();
  });

  function updateStyles() {
    const theme = themes[currentTheme];

    Object.entries(theme.colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--theme_${key}_color`, value);
    });
  }

  function toggle() {
    currentTheme = (currentTheme + 1) % themes.length;
    updateStyles();

    Analytics.track('themeToggled');
  }
</script>

<style>
  .theme-button {
    cursor: pointer;
    height: 24px;
  }
</style>

<div on:click={toggle} class="theme-button">
  <img src="/images/palette.png" alt="toggle-theme" />
</div>
