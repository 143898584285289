import { writable } from 'svelte/store';

function createFoundations() {
  const { subscribe, set, update } = writable([]);

  return {
    set: set,
    subscribe,
    update: update
  };
}

export const foundations = createFoundations();
