import { get, writable } from 'svelte/store';

function pushUndo(event, undoStack) {
  undoStack.set(get(undoStack).concat(event));
}

function undo(undoStack) {
  let stack = get(undoStack);

  const event = stack.pop();

  if (event) {
    event.undo();
  }

  undoStack.set(stack);
}

function createUndoStack() {
  const undoStack = writable([]);
  const { subscribe, set, update } = undoStack;

  return {
    add: (event) => pushUndo(event, undoStack),
    undo: () => undo(undoStack),
    set: set,
    subscribe,
    update: update
  };
}

export const UndoStack = createUndoStack();
