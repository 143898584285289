<script context="module">
	import { scale } from 'svelte/transition';
	import { expoOut } from 'svelte/easing';
  import { get, writable } from 'svelte/store';
  import { playSound } from '../header/sound.svelte';

  const score = writable(0);
  const increasing = writable(false);

  export function addScore(value) {
    const newScore = Math.max(0, get(score)+value);
    const scoreChange = get(score) - newScore;
    score.set(newScore);

		if (value === 5) {
			playSound('score');
		} else if (value === 10 || value === 15) {
			playSound('foundation');
		}

    if (scoreChange < 0) {
      increasing.set(true)
      setTimeout(() => increasing.set(false), 250);
    }

    return scoreChange;
  }

  export function resetScore() {
    score.set(0);
  }
</script>

<style>
  .score-display {
    left: 50%;
    position: absolute;
  }
</style>

{#if $increasing}
<div class="score-display" in:scale="{{duration: 250, delay: 0, opacity: 1, start: 1.5, easing: expoOut}}">
  {$score}
</div>
{:else}
<div class="score-display">
  {$score}
</div>
{/if}
